@import '~bootstrap/scss/bootstrap';
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

body {
  background-color: $body-bg-dark;
}

.catalog {
  display: grid;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 10px;
}

.c-pointer {
  cursor: pointer;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alice-carousel {
  display: grid;
  gap: 8px;
  grid-template-columns: 64px 1fr 64px; 
  grid-template-rows: 1fr 24px; 
  grid-template-areas: 
    "slides slides slides"
    "prev dots next";   

  & > div:first-child {
    grid-area: slides;
  }

  & &__dots {
    grid-area: dots;
    margin: 0;
    &-item {
      &.__active {
        background-color: $primary;
      }
    }
  }
  & &__prev-btn, & &__next-btn {
    width: auto;
    text-align: center;
    padding: 0;
  }

  & &__prev-btn {
    grid-area: prev;
  }

  & &__next-btn {
    grid-area: next;
  }
}

@include media-breakpoint-up(lg) {
  .alice-carousel {
    display: grid;
    grid-template-columns: 64px 1fr 64px; 
    grid-template-rows: 1fr 24px; 
    grid-template-areas: 
      "slides slides slides"
      "prev dots next";  
  }
}

.fs-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;

  svg {
    margin: 0 !important;
  }
}

.iframe {
  --bs-aspect-ratio: 56.25%;
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;

  position: fixed;
  width: 100%;
  height: 100%;    
  top: 0;
  left: 0;
  
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
  
  iframe {
    background-color: black;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1024px) {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
  }
}